var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Leaderboards"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Player "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchPlayer),callback:function ($$v) {_vm.searchPlayer=$$v},expression:"searchPlayer"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersPlayer,"single-select":true,"show-select":"","items":_vm.lbp,"item-key":_vm.lbp.id,"items-per-page":5,"search":_vm.searchPlayer,"sort-desc":[false, true],"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
            }},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{attrs:{"multiple":false,"value":item},on:{"change":function($event){return _vm.SelectedPlayerData()}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Alliance "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchAlliance),callback:function ($$v) {_vm.searchAlliance=$$v},expression:"searchAlliance"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersAlliance,"items":_vm.lba,"items-per-page":5,"search":_vm.searchAlliance,"sort-desc":[false, true],"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
            }}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('h3',{staticClass:"data_crud"},[_vm._v("Player: "+_vm._s(_vm.selected.name))])]),_vm._l((_vm.lbpl),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"4"}},[_c('v-card',{staticClass:"mx-auto box_shadow_backgroud",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" Winrate: "+_vm._s(item.wins)+" / "+_vm._s(item.losses)+" ")]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Subfaction: "+_vm._s(item.subfaction)+" ")])],1),_c('v-list-item-avatar',{attrs:{"size":"80"}},[_c('v-img',{attrs:{"src":require('../../../src/assets/images/custom/' + item.img),"alt":"test"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"href":'https://gijs-verbruggen.com/pdf/' + item.pdf + '.pdf',"color":"primary","rounded":"","download":""}},[_vm._v(" Download "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-download ")])],1)],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }